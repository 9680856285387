import React, { useEffect } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'
import Block1 from '../../../components/details/blocks/block1'
import { useTranslation } from 'react-i18next'
import Icon from '../../../components/icons/Icon'
import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'


export default function Empty() {
  const { winSize } = useAppContext()
  const { t }:any = useTranslation(['common', 'about'])

  useEffect(() => {
    
    document.title = `Teddra - ${t('resources.platform.label')}`
  }, []);
  return (
    <div className="w-full h-full">
        <DetailsLayout navigateBack='/home'>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks">
                        
                      
                    
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
