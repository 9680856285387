import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'
import { Trans, useTranslation } from 'react-i18next'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import ReactSvg from '../../../components/shared/ReactSvg'


export default function Platform() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const [scrollTo, setscrollTo] = useState(undefined)
  const {t}:any = useTranslation(['common', 'platform'])

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop + 90)
  }

  useEffect(() => {
    
    document.title = `Teddra - ${t('resources.platform.label')}`
  }, []);
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home' scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks3">
                        <div className='space-y-7'>
                            <div className="flex space-between-2cols  pl-page h-[calc(100vh-192px)] min-h-[900px] items-center relative">
                                <div>
                                    <div className='  space-y-[90px]' >
                                        <div className='w-[calc(100%-546px)]'>
                                            <p className="label-level2 ">
                                                {/* {t('resources')?.platform?.label} */}
                                                La plateforme Qorddu
                                            </p>
                                            <p className='title-level1'>
                                                {/* <Trans i18nKey={"resources.platform.titlePage"} components={{br: <br/>}}/> */}
                                                Grille de Recherche pour le web professionnel
 
 
 
                                            </p>
                                            <p className="text-level1">
                                            {/* {t('platform:header').text} */}
                                            Qorddu est une plateforme conçue pour déployer et gérer plusieurs milliers de moteurs de recherche spécialisés, connectés les uns aux autres et capables d’offrir un accès rapide, pertinent et intuitif à l’information collective sur le web.
 
                                            </p>
                                           

                                        </div>
                                    
                                        {/* <div className="grid grid-cols-3 gap-x-[50px] w-[calc(100%-355px)]">
                                            <div>
                                                <div className="space-y-5">
                                                    <Icon name='Naviguer' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        <p className="text-level2 font-bold">{t('platform:header.p1.title')}</p>
                                                        <p className="text-level2">
                                                        {t('platform:header.p1.text')}
                                                        </p>

                                                    </div>

                                                </div>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn ' onClick={() => scrollToSection(ref1)}>
                                                    {t('btn')}
                                                </button>
                                            </div>
                                            <div>
                                                <div className="space-y-5">
                                                    <Icon name='Search' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        <p className="text-level2 font-bold">{t('platform:header.p2.title')}</p>
                                                        <p className="text-level2">
                                                        {t('platform:header.p2.text')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref2)}>
                                                    {t('btn')}
                                                </button>
                                            </div>
                                            <div>
                                                <div className='space-y-5'>
                                                    <Icon name='Exploitez' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        <p className="text-level2 font-bold">{t('platform:header.p3.title')}</p>
                                                        <p className="text-level2">
                                                        {t('platform:header.p3.text')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref3)}>
                                                    {t('btn')}
                                                </button>
                                            </div>

                                            
                                        </div> */}

                                    </div>
                                 
                                </div>
                                {/* <div className='flex-1 flex items-center justify-center h-[513px]  rounded-l-[22px] absolute right-[30px] top-[84px]'>
                                    <Icon name='Disque' className='w-full h-full'/>
                                </div> */}
                                {winSize.h > 700 && (
                                <div className='absolute bottom-[40px] w-full flex justify-center left-0 '>
                                    <button onClick={() => scrollToSection(ref1)}>
                                        <ChevronDownIcon className='w-[50px] h-[50px]'/>

                                    </button>
                                </div>

                                )}
                            </div>
                           

                        </div>
                       
                      
                        <div className="mt-[180px]">
                            
                            <div className="relative h-[1200px] flex flex-col justify-center" ref={ref1}>
                                <div className='flex space-x-[55px] justify-between items-center px-page '>
                                    <div className=" w-[50%] space-y-10">
                                        <div className=''>
                                            <TextBlock2
                                                // label={t('platform:p1.label')}
                                                label='Constat'
                                                // title={t('platform:p1.title')}
                                                title='Pourquoi Qorddu ?'
                                                // text={t('platform:p1.text')}
                                                text='93% des sessions web démarrent par une recherche via un des grands moteurs de recherche. Or, ces derniers montrent leurs limites :'
                                            />

                                        </div>
                                        <div className=" w-full space-y-8 -ml-[14px]">
                                           
                                            <div className='space-x-7 flex'>
                                            <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                                <div className='flex-1'>
                                                
                                                    <p className="text-level2 !mt-0">
                                                    Aucune possibilité de navigation et de recherche ciblée sur un pays, une région, un secteur, un métier…
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='space-x-7 flex'>
                                              
                                                <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                                <div className='flex-1'>
                                                   
                                                    <p className="text-level2 !mt-0">
                                                    Une interface utilisateur centrée sur des concepts informatiques : url, pages web, jpeg, pdf…
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='space-x-7 flex'>
                                                <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                                <div className='flex-1'>
                                                    
                                                    <p className="text-level2 !mt-0">
                                                    Une exploitabilité des réponses axée sur le « clic », comme si le cycle de l’information s’arrêtait à sa visualisation.
                                                    </p>
                                                </div>
                                            </div>
                                               
                                     
                                            

                                            
                                        </div>
                                      

                                    </div>
                                    <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px] ' style={{background: ' -439px -1522px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                </div>

                            </div>
                           

                            <div className='relative h-[1200px] flex flex-col justify-center' ref={ref2}>
                                <div className='flex justify-end   items-center px-page '>
                                    <div className='w-[900px] h-[900px] rounded-full border absolute left-[-395px]' style={{background: '-658px -1050px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    <div className=" w-[50%] space-y-10">
                                        <div className="">
                                            <TextBlock2
                                                // label={t('platform:p2.label')}
                                                label='Concept'
                                                title="La Grille de Recherche Qorddu"
                                                // title={t('platform:p2.title')}
                                                // text={t('platform:p2.text')}
                                                text='Qorddu est une Grille de Recherche. A l’inverse des moteurs de recherche traditionnels, monolithiques et centralisés, Qorddu s’appuie sur un vaste réseau de moteurs de recherche spécialisés, caractérisés par :'
                                            />

                                        </div>
                                        <div className=" w-full space-y-8 -ml-[14px]">
                                           
                                           <div className='space-x-7 flex'>
                                           <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                               <div className='flex-1'>
                                               
                                                   <p className="text-level2 !mt-0">
                                                   Une organisation matricielle (effet grille) en « domaines » (Informatique, industrie…) et zones géographiques (pays, région…).
                                                   </p>
                                               </div>
                                           </div>
                                           <div className='space-x-7 flex'>
                                             
                                               <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                               <div className='flex-1'>
                                                  
                                                   <p className="text-level2 !mt-0">
                                                   Chaque moteur Qorddu offre un accès aux ressources web de « sa zone » (pages web, images jpeg…) mais aussi à une sémantique unique du monde réel (produits, services, formations, cours…).
                                                   </p>
                                               </div>
                                           </div>
                                           <div className='space-x-7 flex'>
                                               <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                               <div className='flex-1'>
                                                   
                                                   <p className="text-level2 !mt-0">
                                                   Une exploitabilité des réponses axée sur le « clic », comme si le cycle de l’information s’arrêtait à sa visualisation.
                                                   </p>
                                               </div>
                                           </div>
                                              
                                    
                                           

                                           
                                       </div>
                                         
                                    </div>
                                </div>

                            </div>

                          
                            <div className="relative h-[1200px] flex flex-col justify-center" ref={ref3}>
                                <div className='space-y-4 px-page py-block-lg'>
                                    <div className='flex justify-between items-center'>
                                        <div className="w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    // label={t('platform:p3.label')}
                                                    label='Les domaines Qorddu'
                                                    // title={t('platform:p3.title')}
                                                    title="Les verticales de la grille Qorddu
 "
                                                    // text={t('platform:p3.text')}
                                                    text='Informatique, Industrie, Santé, Biotech, l’écosystème, Jardinage, Beauté, Agro-alimentaire, la Play Station… Qorddu est nativement pensée pour se déployer sur des centaines, voire des milliers de verticales Ci-dessous les domaines en préparation pour 2025 :'
                                                />
                                            </div>
                                            <div className="mt-10 space-y-8">
                                              
                                                <div className='grid grid-cols-2 gap-4 w-max'>
                                                    <div className='space-y-3'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <ReactSvg className='w-full h-full' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fvolumes%2Fit_icon.svg?alt=media&token=5cede60e-325e-42a0-9785-99b499f84602'/>
                                                            </div>
                                                            <p className='text-level2'>Technologies de l’Information</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <ReactSvg className='w-full h-full ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Findustry.svg?alt=media&token=aa4500dd-49b3-4326-9021-a6b7f85e12cf'/>
                                                            </div>
                                                            <p className='text-level2'>Industrie</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <ReactSvg className='w-full h-full' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Ffood.svg?alt=media&token=939f401f-496e-4a94-9c2b-beaa43a9488d'/>
                                                            </div>
                                                            <p className='text-level2'>Agro-alimentaire</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <ReactSvg className='w-full h-full ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Finnovation_places.svg?alt=media&token=8c05c329-a8cd-49bf-8f93-9b9b0fcc7da1'/>
                                                            </div>
                                                            <p className='text-level2'>Innovation Places</p>
                                                        </div>
                                                    
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <ReactSvg className='w-full h-full ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Ffinance.svg?alt=media&token=d133e024-0ac0-4504-8e8d-5f90f53f02c9'/>
                                                            </div>
                                                            <p className='text-level2'>Finance</p>
                                                        </div>
                                                    
                                                

                                                    </div>
                                                    <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg  '>
                                                                <ReactSvg className='w-full h-full  ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Fentrepre.svg?alt=media&token=de159738-4cd7-4358-974c-22868af8c55a'/>
                                                            </div>
                                                            <p className='text-level2'>Entrepreneuriat</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg  '>
                                                                <ReactSvg className='w-full h-full  ' src='https://firebasestorage.googleapis.com/v0/b/teddra-api-prod-5f1fb.appspot.com/o/icons%2Fdomain%2Fhigher_education.svg?alt=media&token=83ac7810-c2a4-4d4c-9562-0ae3faa3ef54'/>
                                                            </div>
                                                            <p className='text-level2'>Enseignement Sup.</p>
                                                        </div>
                                                    
                                                     
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                            </div>
                                                            <p className='text-level2'>...</p>
                                                        </div>

                                                    </div>
                                                
                                                
                                                
                                                
                                                </div>

                                            </div>   
                                            

                                        </div>
                                        <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px]' style={{background: ' -3151px -227px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    </div>
                                
                                </div>
                            </div>

                           

                           
                            <div className='relative h-[1200px] flex flex-col justify-center' ref={ref2}>
                                <div className='flex justify-end   items-center px-page '>
                                    <div className='w-[900px] h-[900px] rounded-full border absolute left-[-395px]' style={{background: '-658px -1050px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    <div className="w-[50%] space-y-10">
                                            <div className=''>
                                                <TextBlock2
                                                    // label={t('platform:p3.label')}
                                                    label='Les Serveurs Qorddu'
                                                    // title={t('platform:p3.title')}
                                                    title="Des moteurs de recherche spécialisés ultra puissants
 "
                                                    // text={t('platform:p3.text')}
                                                    text='Un « Serveur » Qorddu est un moteur de recherche spécialisé, dédié à une zone précise du web et du monde réel : une région, un secteur d’activités, etc'
                                                />
                                            </div>
                                            <div className=" w-full space-y-8 -ml-[14px]">
                                           
                                                <div className='space-x-7 flex'>
                                                <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                                    <div className='flex-1'>
                                                    
                                                        <p className="text-level2 !mt-0">
                                                        Un index et un contenu par définition « spécialisé » qui le situent au même niveau de richesse qu’un Sephora pour le secteur Beauté ou le Vieux Campeur pour les amoureux de la montagne.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='space-x-7 flex'>
                                                    
                                                    <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                                    <div className='flex-1'>
                                                        
                                                        <p className="text-level2 !mt-0">
                                                        Une pertinence d’un genre nouveau fondée sur une sémantique du monde réel (produits, services… plus de 100 types de publications) et non une sémantique de machines..
                                                        </p>
                                                    </div>
                                                </div>
                                            <div className='space-x-7 flex'>
                                                <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                                <div className='flex-1'>
                                                    
                                                    <p className="text-level2 !mt-0">
                                                    Une exploitabilité des réponses basées sur un environnement riche, ouvert et commune à tous les moteurs : prévisualiser, épingler, partager, contacter…
                                                    </p>
                                                </div>
                                            </div>
                                              
                                    
                                           

                                           
                                            </div>
                                            

                                        </div>
                                </div>

                            </div>

                            

                            {/* <div className="relative h-[1200px] flex flex-col justify-center">
                                <div className='space-y-4 px-page py-block-lg'>
                                    <div className='flex justify-between items-center'>
                                    <div className=" w-[50%]">
                                        <div className="">
                                            <TextBlock2
                                                // label={t('platform:p2.label')}
                                                label=''
                                                title="Le partenariat avec la presse"
                                                // title={t('platform:p2.title')}
                                                // text={t('platform:p2.text')}
                                                text='Presse spécialisée, presse régionale, presse institutionnelle (chambres de commerce, presse des collectivités…), presse commerciale (marques, réseaux d’entreprises…). Le déploiement des domaines et serveurs Qorddu s’appuie sur un « deal » avec les acteurs de la presse : nous nous chargeons de la plateforme et des données, assurez la promotion et la commercialisation der serveurs Qorddu. Nous partageons les revenus 50/50.'
                                            />

                                        </div>
                                         
                                    </div>
                                        <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px]' style={{background: ' -3151px -227px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    </div>
                                
                                </div>
                            </div> */}
                            <div className="relative h-[1200px] flex flex-col justify-center">
                                <div className='space-y-4 px-page py-block-lg'>
                                    <div className='flex justify-between items-center'>
                                    <div className=" w-[50%] space-y-10">
                                        <div className="">
                                            <TextBlock2
                                                // label={t('platform:p2.label')}
                                                label='La proposition Qorddu'
                                                title="Une expérience Search inédite"
                                                // title={t('platform:p2.title')}
                                                // text={t('platform:p2.text')}
                                                text='Tout est pensé pour faire d’un serveur Qorddu la porte d’entrée vers une zone précise du web et du monde réel :'
                                            />

                                        </div>
                                        <div className=" w-full space-y-8 -ml-[14px]">
                                           
                                           <div className='space-x-7 flex'>
                                           <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                               <div className='flex-1'>
                                               
                                                   <p className="text-level2 !mt-0">
                                                   La spécialisation, une caractéristique qui fait a fait ses preuves dans le commerce (Sephora…), le médecine (pédiatrie…) et tant d’autres domaines de notre vie quotidienne.
                                                   </p>
                                               </div>
                                           </div>
                                           <div className='space-x-7 flex'>
                                               
                                               <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                               <div className='flex-1'>
                                                   
                                                   <p className="text-level2 !mt-0">
                                                   La nature des données, fondée sur une représentation du monde réel : ses entreprises, ses produits, ses services, ses formations…
                                                   </p>
                                               </div>
                                           </div>
                                       <div className='space-x-7 flex'>
                                           <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                           <div className='flex-1'>
                                               
                                               <p className="text-level2 !mt-0">
                                               La richesse de l’interface utilisateur conçue pour aller bien plus loin que le « clic » et permettre de partager des données, de communiquer avec l’auteur, etc.
                                               </p>
                                           </div>
                                       </div>
                                       <div className='space-x-7 flex'>
                                           <ChevronRightIcon className='w-[50px] h-[50px]'/>
                                           <div className='flex-1'>
                                               
                                               <p className="text-level2 !mt-0">
                                               Et le modèle en grille, qui fait de chaque moteur Qorddu un point d’entrée vers tous les moteurs de la grille.
                                               </p>
                                           </div>
                                       </div>
                               
                                      

                                      
                                       </div>
                                         
                                    </div>
                                        <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px]' style={{background: ' -3151px -227px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    </div>
                                
                                </div>
                            </div>

                         
                            
                            
                          
                        </div>
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
