import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Icon from '../components/icons/Icon'
import { Breadcrumb } from '../components/shared/breadcrumb'
import { useAppContext } from '../context/appContext'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import TopbarIcons from '../components/shared/bars/TopbarIcons'
import ResourcesBar from '../components/shared/bars/resourcesBar'
import LanguageSwitcher from '../components/Switch'
import Divider from '../components/shared/Divider'

export default function GlobalLayout() {
    const {breadcrumb, user} = useAppContext()
    const navigate = useNavigate()
    const [time, setTime] = useState(new Date());

    const [date, setDate] = useState(new Date());
    
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000); 

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const updateDate = () => {
      setDate(new Date());
    };

    const now = new Date();
    const msUntilMidnight = 
      new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() - now.getTime();

    const timeout = setTimeout(() => {
      updateDate();

      setInterval(updateDate, 86400000);
    }, msUntilMidnight);

    return () => clearTimeout(timeout);
    
  }, []);

  const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const formattedDate = date.toLocaleDateString();
  return ( 
    <div className='flex flex-col'>
        <div className="bar-h flex px-7 items-center justify-between">
            <div className="flex space-x-2 items-center">
                <Icon name='TeddraLogo' className='w-[32px] h-[32px]'/>
                <p className='font-bold text-[14px]'>Qorddu</p>

            </div>
            <div className="flex space-x-6 items-center">
              {user && (
                <div className='flex items-center space-x-2'>
                  <Icon name='User' className='icon-sm'/>
                  {/* {user.photo ? <img className='w-[30px] h-[30px] rounded-full' src={user.photo}/> : <div></div>} */}
                  <p>Welcome!, {user.name}</p>
                </div>
              )} 
              <Divider/>
              <div className='flex items-center space-x-4'>
                <LanguageSwitcher menu/>
                <Divider borderC='mx-2'/>
                <TopbarIcons main close={() => {}}/>

              </div>

            </div>
        </div>
        <div className="bar-h px-7 flex items-center border-b border-main space-x-2">
            <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' onClick={() => navigate(-1)} disabled={true}>
                <ArrowLeftIcon className='icon-sm'/>
            </button>
            <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' disabled={true} onClick={() => navigate(1)}>
                <ArrowRightIcon className='icon-sm'/>
            </button>
            <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' disabled={true} >
                <Icon name='Refresh' className='icon-sm'/>
            </button>
            <Breadcrumb
                routes={breadcrumb}
                maxElement={3}
            />
        </div>
        <div className="bar-h  px-7 items-center ">
        
            <ResourcesBar/>
        </div>
        <div className="flex flex-1">
            
            
            <Outlet/>
                

        </div>
        <div className='bar-h flex items-center border-t border-main'>
        <div className=" flex justify-end flex-1 px-3"><div className="flex text-xs space-x-4 justify-end items-center"><p className="cursor-not-allowed text-teddra-gray-200 only-pc">Copyright</p><p className="cursor-not-allowed text-teddra-gray-200 only-pc">Confidentialité</p><p className="cursor-not-allowed text-teddra-gray-200 only-pc">Cookies</p><p className="cursor-not-allowed text-teddra-gray-200 only-pc">Mentions légales</p><p>{formattedTime}</p><p>{formattedDate}</p><div className="cursor-not-allowed text-teddra-gray-200"><svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path></svg></div><div className="cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"></path></svg></div></div></div>

        </div>
    </div>
  )
}
