import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TopbarIcons from '../../shared/bars/TopbarIcons';

import DetailsBar from '../../shared/bars/resourcesBar/DetailsBar';
import OuterContainer from './OuterContainer';
import { useTranslation } from 'react-i18next';

export default function DetrailsLayoutSm({children, navigateBack, fullWidth, hidden, scrollTo}: {children:JSX.Element, navigateBack:string, fullWidth?:boolean, hidden?:boolean, scrollTo? : number}) {
    const [open, setopen] = useState(false);
    const navigate = useNavigate()
    const { i18n } = useTranslation()

    useEffect(() => {
      setopen(true)
    }, [])
    
  return (
    <Drawer
        visible={open}
        width={1270}
        mask={false}
    >
      <>
      <div className="bar-h flex justify-end items-center px-4 ">
          <TopbarIcons main={false} close={() => navigate(`/${i18n.language}${navigateBack}`)}/>

      </div>
      <div className="bar-h flex items-center px-4">
        <DetailsBar/>
      </div>
    
      {fullWidth ? (
          children
        ) : (
          <OuterContainer size='sm' hidden={hidden} scrollTo={scrollTo}>
            {children}
          </OuterContainer>
        )}
  
      </>
    </Drawer>
  )
}
