import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={-0.5}
      y={-0.5}
      width={23}
      height={23}
      transform="matrix(0 -1 -1 0 23.0002 22.9995)"
      stroke="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0002 15.9995V18.9995C14.0002 20.1045 14.8952 20.9995 16.0002 20.9995H19.0002C20.1052 20.9995 21.0002 20.1045 21.0002 18.9995V15.9995C21.0002 14.8945 20.1052 13.9995 19.0002 13.9995H16.0002C14.8952 13.9995 14.0002 14.8945 14.0002 15.9995Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0002 4.99951V7.99951C14.0002 9.10451 14.8952 9.99951 16.0002 9.99951H19.0002C20.1052 9.99951 21.0002 9.10451 21.0002 7.99951V4.99951C21.0002 3.89451 20.1052 2.99951 19.0002 2.99951L16.0002 2.99951C14.8952 2.99951 14.0002 3.89451 14.0002 4.99951Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00024 15.9995L3.00024 18.9995C3.00024 20.1045 3.89524 20.9995 5.00024 20.9995H8.00024C9.10524 20.9995 10.0002 20.1045 10.0002 18.9995V15.9995C10.0002 14.8945 9.10524 13.9995 8.00024 13.9995H5.00024C3.89524 13.9995 3.00024 14.8945 3.00024 15.9995Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.00024 6.49951L4.00024 6.49951"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.50024 3.99951L6.50024 8.99951"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
