import { Form, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from '../../../../utils/firebase';
import Icon from '../../../../components/icons/Icon';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../../../components/Switch';

export default function Login() {
    const [open, setopen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<any>(null);
    const [Status, setStatus] = useState(false);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()
    const { t }:any = useTranslation(['login'])
    const submitForm = (event:any) => {
        event.preventDefault();
        setloading(true)
        setErrors(null)
        signInWithEmailAndPassword(auth , email, password).then( user => {
          
            navigate('/fr')

         
        }).catch(err => setErrors('Invalid credentials')).finally(() => setloading(false))
      };
    useEffect(() => {
      setopen(true)
    }, [])
    
  return (
   
       

        <div className='rounded-[25px] h-max  flex flex-col justify-center relative overflow-hidden '>
            <div className="flex items-center h-full justify-between  w-full px-[130px] relative">
                    <div className="max-[1600px]:w-[45%] w-[43%] flex justify-between items-center absolute top-[50px] max-[1600px]:ml-[20px] ml-[112px]">
                        <div className="flex space-x-2 items-center">
                            <Icon name='TeddraLogo' className='w-[65px] h-[65px]'/>
                            <p className='font-bold text-[20px]'>Qorddu</p>

                        </div>
                        <LanguageSwitcher/>
                    </div>

                <div className=" max-[1600px]:w-[50%] w-[43%] mr-[123px] py-5 max-[1600px]:ml-[20px] ml-[112px]">
                        <div className='mt-14'>
                            <p className="font-bold text-[29px] uppercase">{t('title')}
                            </p>
                            <p className="text-[28px] uppercase leading-[40px] font-light">
                            {t('text')}
    
                            </p>
                            <p className="text-[28px]  leading-[40px] font-light mt-[50px]">
                            {t('titleGroup')}
    

                            </p>
                            <div className="flex w-full">
                                <div className='max-w-[290px] w-full mt-10'>
                                    <Form className="space-y-3 flex-1">
                                        <Form.Item
                                            validateStatus={errors ? "error" : ''}
                                            help={errors}
                                            className="group"
                                            style={{ marginBottom: "0" }}
                                            required
                                        >
                                            <input
                                            type="email"
                                            placeholder={t('inputEmail')}
                                            className={`rounded-lg py-2 px-3 block w-full h-[50px] !text-[18px] placeholder:text-[18px]`}
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setErrors(null);
                                            }}
                                            required
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            validateStatus={errors ? "error" : ''}
                                            help={errors}
                                            className="group"
                                            style={{ marginBottom: "0" }}
                                            required
                                        >
                                            <input
                                            type="password"
                                            placeholder={t('inputPassword')}
                                            className="rounded-lg py-2 px-3 block w-full h-[50px] !text-[18px] placeholder:text-[18px]"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                setErrors(null);
                                            }}
                                            required
                                            />
                                        </Form.Item>

                                        <Spin spinning={loading}>
                                            <button
                                            className="px-7  text-white rounded-lg py-1.5 w-max block ml-auto disabled:text-teddra-gray-200 h-[50px] text-[18px] "
                                            type="submit"
                                            disabled={loading}
                                            onClick={submitForm}
                                            style={{background: '#000'}}
                                            >
                                            {t('submit')}
                                            </button>
                                        </Spin>
                                    </Form>

                                </div>

                            </div>

                        </div>
                
                </div>
                
            </div>
            <div className='absolute top-[-99px] max-[1600px]:right-[-150px] right-[-214px] h-[calc(100%+140px)] w-[50%]'>
                 <div className="bg-skin-fill-muted h-full w-full -rotate-[8deg]" style={{background: 'url(../images/cyber-security.png)', backgroundRepeat: 'no-repeat',backgroundPosition: '-3px -133px' }}></div>                   
            </div>
        

        </div>
     
        
  )
}
