import React from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'
import { useTranslation } from 'react-i18next'



export default function HomeLayout() {
  const { t }:any = useTranslation(['common'])
  return (
    <ResourcesLocationLayout data={[
      {
        title: 'Plateforme',
        key: '/platforme',
        blocks: [
            {
                key: '',
                name: 'Accueil',
                icon: '',
                description: 'Acteurs de la presse Rejoingnez la Grille de Recherche Qorddu et revenez au premier plan sur le web. Ensemble.',
                main:true
            },
           
            {
                key: '/home/platform',
                name: "La plateforme Qorddu",
                icon: '',
                description: "Grille de Recherche pour le web professionnel",
            },
            {
                key: '/qorddu-for',
                name:'Acteurs de la presse',
                icon: '',
                description:'Devenez partenaire et revenez au premier plan',
             
            },
            {
              key: '/home/how-it-works',
              name: 'Comment ca marche ?',
              icon: '',
              description: 'Tout est dans le cloud',
             
          },
          {
            key: '/home/avantages',
            name: 'Quels benefices ?',
            icon: '',
            description: 'Ramenez le business du search vers vous',
      
        }
          ,
          {
              key: '/home/demo',
              name: 'Demandez une demo',
              icon: 'Demo',
              description: "Nous créons votre moteur pour deux mois d'essai.",
          },
          
         
        ]
    },
      {
        title: 'Domaines',
        key: '/home',
        blocks: [
          {
            key: '/domains',
            name: 'Tous les domains',
            icon: '',
            description:'',
            main:true,
            disabled:true
          },
          {
            key: '/domains-p',
            name: 'Domaines en preparation',
            icon: '',
            description:'',
            disabled:true
            
          },
         
        
          {
            key: '/proposez-domain',
            name: 'Proposez un domaine',
            icon: 'Gridplus',
            description:'lorem ipsum',
            disabled:true
          },
        ]
       },
     
  //   {
  //     key:'/products',
  //     title:'Products',
  //     blocks:[
  //       {
  //         key: '/expert',
  //         name: 'Qorddu for Expert',
  //         icon: '',
  //         description: 'lorem ipsum',
  //     },
  //     {
  //       key: '/business',
  //       name: 'Qorddu for Business',
  //       icon: '',
  //       description: 'lorem ipsum',
  //   },
  //   {
  //     key: '/catalogue',
  //     name: 'Qorddu for Catalogue',
  //     icon: '',
  //     description: 'lorem ipsum',
  //   },
  //   {
  //     key: '/entreprise',
  //     name: 'Qorddu for Entreprise',
  //     icon: '',
  //     description: 'lorem ipsum',
  // },
  // {
  //   key: '/community',
  //   name: 'Qorddu for Community',
  //   icon: '',
  //   description: 'lorem ipsum',
  // },
  // {
  //   key: '/local',
  //   name: 'Qorddu for Local',
  //   icon: '',
  //   description: 'lorem ipsum',
  // },
  // {
  //   key: '/vertical',
  //   name: 'Qorddu for Vertical',
  //   icon: '',
  //   description: 'lorem ipsum',
  // },
  // {
  //   key: '/education',
  //   name: 'Qorddu for Education',
  //   icon: '',
  //   description: 'lorem ipsum',
  // },
  // {
  //   key: '/ecosystem',
  //   name: 'Qorddu for Ecosystem',
  //   icon: '',
  //   description: 'lorem ipsum',
  // },

  //     ]
  //   },
    // {
    //   key: '/solutions',
    //   title: 'Solutions',
    //   blocks:[
    //     {
    //       key: '/solution/pro',
    //       name: 'Pro',
    //       icon: '',
    //       description: 'lorem ipsum',
    //     },
    //     {
    //       key: '/solution/business',
    //       name: 'Business',
    //       icon: '',
    //       description: 'lorem ipsum',
    //     },
    //     {
    //       key: '/solution/community',
    //       name: 'Community leader',
    //       icon: '',
    //       description: 'lorem ipsum',
    //     },
    //     {
    //       key: '/solution/publisher',
    //       name: 'Publisher',
    //       icon: '',
    //       description: 'lorem ipsum',
    //     },
    //     {
    //       key: '/solution/brand',
    //       name: 'Brand',
    //       icon: '',
    //       description: 'lorem ipsum',
    //     },
    //   ]
    // },

    {
      key:'/resources',
      title:'Ressources',
      blocks:[
        {
          key: '/help',
          name: 'Help and faq',
          icon: '',
          description: 'lorem ipsum',
          disabled:true
        },
        {
          key: '/user-case',
          name: 'User case',
          icon: '',
          description: 'lorem ipsum',
          disabled:true
        },
      ]
    },
    {
      key:'/about',
      title:'A propos',
      blocks:[
        {
          key: '/about',
          name: 'About us',
          icon: '',
          description: 'lorem ipsum',
          disabled:true
        },
        {
          key: '/hire',
          name: 'We are hiring',
          icon: '',
          description: 'lorem ipsum',
          disabled:true
        },
        {
          key: '/contact',
          name: 'Contact us',
          icon: '',
          description: 'lorem ipsum',
          disabled:true
        },
      ]
    }
  //   {
  //     title: 'Home',
  //     key: '/home',
  //     blocks: [
  //         {
  //             key: '/home/about',
  //             name: t('resources').about?.label,
  //             icon: '',
  //             description: t('resources').about?.title,
  //             disabled:true
  //         },
  //         {
  //             key: '/home/hiring',
  //             name: t('resources').jobs?.label,
  //             icon: '',
  //             description: t('resources').jobs?.title,
  //             disabled:true
  //         },
  //         {
  //           key: '/home/contact',
  //           name: t('resources').contact?.label,
  //           icon: '',
  //           description: t('resources').contact?.title,
  //           disabled:true
  //       },
        
        
  //     ]
  // },
    ]}>
      <></>
    </ResourcesLocationLayout>
  )
}
