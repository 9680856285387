import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector) // This needs to be before initReactI18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    returnObjects:true,
    supportedLngs: ['en', 'fr'],
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    ns: ['common', 'login', 'platform', 'solution', 'htw', 'avantage', 'home'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
