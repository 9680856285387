import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'
import { Trans, useTranslation } from 'react-i18next'


export default function Home() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const [scrollTo, setscrollTo] = useState(undefined)
  const {t}:any = useTranslation(['common', 'platform'])

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop + 90)
  }

  useEffect(() => {
    
    document.title = `Teddra - ${t('resources.platform.label')}`
  }, []);
  return (
    <div className="relative">
        <DetailsLayout navigateBack='/home' hidden scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                <div className={`space-between-main-blocks3 ${winSize.w < maxLgWinWidth ? ' h-[calc(100vh-120px)] ':' h-[calc(100vh-200px)] '} overflow-auto`}>
                        <div className='space-y-7'>
                            <div className="flex space-between-2cols pt-page pl-page min-h-[calc(100vh-192px)]">
                                <div>
                                    <div className='  space-y-[90px]' >
                                        <div className='w-[calc(100%-546px)]'>
                                            <p className="label-level2 ">Accueil</p>
                                            <p className='title-level1'>
                                                {/* <Trans i18nKey={"resources.platform.titlePage"} components={{br: <br/>}}/> */}Acteurs de la presse
                                            </p>
                                            <p className="text-level1">
                                            Rejoingnez la Grille de Recherche Qorddu et revenez au premier plan sur le web. Ensemble.
 
                                            </p>
                                           

                                        </div>
                                    
                                        <div className="grid grid-cols-3 gap-x-[50px] w-[calc(100%-355px)]">
                                            <div>
                                                <div className="space-y-5">
                                                    <Icon name='Naviguer' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        {/* <p className="text-level2 font-bold">{t('platform:header.p1.title')}</p> */}
                                                        <p className="text-level2">
                                                        Offrez une <span className='font-bold text-level2'>expérience Search inédite</span> dans votre secteur, votre région...
 
                                                        </p>

                                                    </div>

                                                </div>
                                                {/* <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn ' onClick={() => scrollToSection(ref1)}>
                                                    {t('btn')}
                                                </button> */}
                                            </div>
                                            <div>
                                                <div className="space-y-5">
                                                    <Icon name='Search' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        {/* <p className="text-level2 font-bold">{t('platform:header.p2.title')}</p> */}
                                                        <p className="text-level2">
                                                        L'union fait la force : 
                                                        <span className='font-bold text-level2'> fédérez-vous en réseau </span>verticaux.
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref2)}>
                                                    {t('btn')}
                                                </button> */}
                                            </div>
                                            <div>
                                                <div className='space-y-5'>
                                                    <Icon name='Exploitez' className='w-[60px] h-[60px]'/>
                                                    <div>
                                                        {/* <p className="text-level2 font-bold">{t('platform:header.p3.title')}</p> */}
                                                        <p className="text-level2">
                                                          
 
                                                            <span className="font-bold text-level2">Proposez des services innovants </span>et ramenez les revenus du Search vers vous.
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <button className='px-4 py-2 border rounded mt-7 block text-[15px] btn' onClick={() => scrollToSection(ref3)}>
                                                    {t('btn')}
                                                </button> */}
                                            </div>

                                            
                                        </div>

                                    </div>
                                 
                                </div>
                                {/* <div className='flex-1 flex items-center justify-center h-[513px]  rounded-l-[22px] absolute right-[30px] top-[84px]'>
                                    <Icon name='Disque' className='w-full h-full'/>
                                </div> */}
                            </div>
                           

                        </div>
                       
                      
                        {/* <div className="mt-[180px]">
                            
                            <div className="relative" ref={ref1}>
                                <div className='flex space-x-[55px] justify-between items-center px-page py-block '>
                                    <div className=" w-[50%]">
                                        <div className=''>
                                            <TextBlock2
                                                label={t('platform:p1.label')}
                                                title={t('platform:p1.title')}
                                                text={t('platform:p1.text')}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                            <p className='text-level2 '>{t('platform:p1.groupe')}</p>
                                            <div className='grid grid-cols-3 gap-3'>
                                                <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Files'/>
                                                        </div>
                                                        <p className='text-level2'>Files</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full ' name='Folders'/>
                                                        </div>
                                                        <p className='text-level2'>Folders</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Directory'/>
                                                        </div>
                                                        <p className='text-level2'>Directories</p>
                                                    </div>

                                                </div>
                                                <div className='space-y-3'>
                                                <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Drive'/>
                                                        </div>
                                                        <p className='text-level2'>Drives</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Server'/>
                                                        </div>
                                                        <p className='text-level2'>Servers</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Cluster'/>
                                                        </div>
                                                        <p className='text-level2'>Cluster</p>
                                                    </div>

                                                </div>
                                                <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg '>
                                                            <Icon className='w-full h-full ' name='Datacenter'/>
                                                        </div>
                                                        <p className='text-level2'>Datacenters</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg '>
                                                        </div>
                                                        <p className='text-level2'>....</p>
                                                    </div>
                                                
                                                    
                                                

                                                </div>
                                            
                                            
                                            
                                            </div>

                                        </div>

                                    </div>
                                    <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px] ' style={{background: 'url("/images/big-data.png") -439px -1522px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                </div>

                            </div>
                            <div className='relative' ref={ref2}>
                                <div className='flex justify-end   items-center px-page py-block '>
                                    <div className='w-[900px] h-[900px] rounded-full border absolute left-[-395px]' style={{background: 'url("/images/big-data.png") -658px -1050px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    <div className=" w-[50%]">
                                        <div className="">
                                            <TextBlock2
                                                label={t('platform:p2.label')}
                                                title={t('platform:p2.title')}
                                                text={t('platform:p2.text')}
                                            />

                                        </div>
                                        <div className="mt-10 space-y-8">
                                            <p className='text-level2 max-w-[463px]'>{t('platform:p2.groupe')}</p>
                                            <div className='grid grid-cols-2 gap-7'>
                                                <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Webpage'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem1')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='ProfilEntreprise'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem2')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full ' name='ProfilPro'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem3')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Products'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem4')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Service'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem5')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='JobOffer'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem6')}</p>
                                                    </div>

                                                </div>
                                                <div className='space-y-3'>
                                                <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='News'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem7')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg  '>
                                                            <Icon className='w-full h-full  ' name='Training'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem8')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='Events'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem9')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='MarketStudy'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem10')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                            <Icon className='w-full h-full' name='AppelsOffer'/>
                                                        </div>
                                                        <p className='text-level2'>{t('platform:p2.groupItem11')}</p>
                                                    </div>
                                                    <div className="flex space-x-3 items-center">
                                                        <div className='icon-lg'>
                                                        </div>
                                                        <p className='text-level2'>...</p>
                                                    </div>

                                                </div>
                                            
                                            
                                            
                                            
                                            </div>

                                        </div>    
                                    </div>
                                </div>

                            </div>
                          
                            <div className="relative" ref={ref3}>
                                <div className='space-y-4 px-page py-block'>
                                    <div className='flex justify-between items-center'>
                                        <div className="w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    label={t('platform:p3.label')}
                                                    title={t('platform:p3.title')}
                                                    text={t('platform:p3.text')}
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8">
                                                <p className='text-level2 '>{t('platform:p3.groupe')}</p>
                                                <div className='grid grid-cols-2 gap-4 w-max'>
                                                    <div className='space-y-3'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Preview'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem1')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full ' name='Filter'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem2')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Pin'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem3')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full ' name='Share'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem4')}</p>
                                                        </div>
                                                    
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full ' name='Plus'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem5')}</p>
                                                        </div>
                                                    
                                                

                                                    </div>
                                                    <div className='space-y-3'>
                                                    <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg  '>
                                                                <Icon className='w-full h-full  ' name='Phone'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem6')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg  '>
                                                                <Icon className='w-full h-full  ' name='Chat'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem7')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg  '>
                                                                <Icon className='w-full h-full  ' name='Badge'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem8')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full ' name='VisitCard'/>
                                                            </div>
                                                            <p className='text-level2'>{t('platform:p3.groupItem9')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                            </div>
                                                            <p className='text-level2'>...</p>
                                                        </div>

                                                    </div>
                                                
                                                
                                                
                                                
                                                </div>

                                            </div>   
                                            

                                        </div>
                                        <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px]' style={{background: 'url("/images/big-data.png") -3151px -227px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div>
                                    </div>
                                
                                </div>
                            </div>
                          
                        </div> */}
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
