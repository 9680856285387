import React from 'react'
import Icon from '../icons/Icon'
import ReactSvg from '../shared/ReactSvg'

type Props = {
    name:string,
    description: string,
    icon:string,
    isImage?:boolean,
    action: (key:string) => void,
    id:string, 
    active:boolean, 
    disabled?:boolean,
    main?:boolean
}
export default function Resource({ name, description, icon, action, active, id, disabled, isImage } : Props) {
  return (
    <div className={`p-3 pb-[18px] pr-5 flex w-[258px] h-max space-x-2 ${active ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'} rounded  ${disabled ? ' cursor-not-allowed opacity-40' : 'cursor-pointer'} `} onClick={() => !disabled ? action(id) : {}}>
        {/* <div className={`w-[70px] h-[70px] relative ${ !icon && 'bg-skin-fill-muted'}`}>
          <Icon className='w-full h-full' name='Document'/>
          

          <div className='absolute flex justify-center items-center  top-0 left-0 w-full h-full'>
            <Icon className='w-[30px] h-[30px]' name={icon}/>

          </div>
        </div> */}
        <div className={`w-[40px] h-[40px] flex justify-end items-start relative  ${disabled && ''}`}>
          {isImage ? (
            <ReactSvg className='w-full h-full' src={icon}/>
          ) : (
            <Icon className='w-full h-full' name={icon ? icon : 'Document'}/>

          )}
          
        </div>
        <div className='flex-1 space-y-[2px]'>
            <p className="font-semibold  text-[16px]">{name}</p>
            <p className={`ellipsis-2 w-full font-light leading-[21px] text-[16px] ${ active ? 'text-white' : ' text-gray-500'}`}>{description}</p>
        </div>
    </div>
  )
}
