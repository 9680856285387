import React, { useEffect, useRef, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2'
import Icon from '../../../components/icons/Icon'
import { useTranslation } from 'react-i18next'


export default function HowItWorks() {
  const { winSize } = useAppContext()
  const ref1 = useRef<any>()
  const ref2 = useRef<any>()
  const ref3 = useRef<any>()
  const ref4 = useRef<any>()
  const { t }:any = useTranslation(['common', 'htw'])
  const [scrollTo, setscrollTo] = useState<any>(undefined)

  const scrollToSection = (ref:any) => {
    setscrollTo(ref?.current?.offsetTop - 20)
  }

  useEffect(() => {
    
    document.title = `Teddra - ${t('resources.howItWorks.label')}`
  }, []);
  return (
    <div className="w-full h-full relative">
        <DetailsLayout navigateBack='/home' scrollTo={scrollTo}>
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                <PageInner>
                    <div className="space-between-main-blocks">
                        <div className='space-y-7'>
                            <div className="flex space-between-2cols  pl-page h-[calc(100vh-192px)] min-h-[900px] items-center relative">
                                <div>
                                    <div className='  space-y-[90px]' >
                                        <div className='max-w-[530px]'>
                                            <p className="label-level2 ">{t('resources.howItWorks.label')}</p>
                                            <p className='title-level1'>Tout est dans le cloud
                                            </p>
                                            <p className="text-level1">
                                            Infra, data, promotion... : votre moteur Qorddu 100% géré dans le cloud.
 
 
                                            </p>
                                           

                                        </div>
                                    
                                      

                                    </div>
                                 
                                </div>
                                {/* <div className='flex-1 flex items-center justify-center h-[513px]  rounded-l-[22px] absolute right-[30px] top-0'>
                                    <Icon name='Disque' className='w-full h-full'/>

                                </div> */}
                            </div>
                           

                        </div>
                       
                      
                        <div className="">
                            <div className=" ">
                                <div className="px-page pb-block">
                                    <div className='flex space-x-[55px] justify-between items-center  ' ref={ref1}>
                                        <div className=" w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    label={'Infrastructure technique'}
                                                    title={t('htw:p1.title')}
                                                    text={t('htw:p1.text')}
                                                
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p1.groupItem1')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p1.groupItem2')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 ">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p1.groupItem3')}</p>
                                                        </div>
                                                        

                                                    </div>
                                                    
                                                
                                                
                                                
                                                </div>

                                            </div>
                                            

                                        </div>
                                        {/* <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px] ' style={{background: 'url("/images/big-data.png") -439px -1522px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div> */}
                                    
                                        <div className=" w-[38%] h-auto">
                                            <Icon name='Platform' className='w-full h-dull'/>
                                        </div>
                                    </div>
                                   

                                </div>

                            </div>
                            <div className=" ">
                                <div className=' px-page py-block'>
                                    <div className='flex justify-between items-center space-x-[55px]  ' ref={ref2}>
                                    
                                        <div className=" left-0 w-[38%] h-auto">
                                            <Icon name='Access' className='w-full h-dull'/>
                                        </div>
                                        <div className=" w-[50%]">
                                            <div className="">
                                                <TextBlock2
                                                    label={t('htw:p2.label')}
                                                    title={t('htw:p2.title')}
                                                    text={t('htw:p2.text')}
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8 ">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p2.groupItem1')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p2.groupItem2')}</p>
                                                        </div>
                                                    
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p2.groupItem3')}</p>
                                                        </div>
                                                    
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p2.groupItem4')}</p>
                                                        </div>
                                                    
                                                    
                                                        

                                                    </div>
                                                    
                                                
                                                
                                                
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                   

                                </div>

                            </div>
                            <div className="">
                                <div className=" px-page py-block">
                                    <div className='flex space-x-[55px] justify-between items-center' ref={ref3}>
                                        <div className="w-[50%]">
                                            <div className=''>
                                                <TextBlock2
                                                    label={t('htw:p3.label')}
                                                    title={'Proposez un index à 360° de votre secteur ou votre région'}
                                                    text={t('htw:p3.text')}
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3 ">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p3.groupItem1')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 items-center">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p3.groupItem2')}</p>
                                                        </div>
                                                        <div className="flex space-x-3 ">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p3.groupItem3')}</p>
                                                        </div>
                                                        

                                                    </div>
                                                    
                                                
                                                
                                                
                                                </div>

                                            </div>
                                            

                                        </div>
                                        <div className=" w-[38%] h-auto">
                                            <Icon name='Contenu' className='w-full h-dull'/>
                                        </div>
                                    </div>
                                        

                                    
                                </div>

                            </div>
                            
                            <div className="">
                                <div className='px-page py-block '>
                                    <div className='flex justify-between items-center space-x-[55px]  ' ref={ref4}>
                                        <div className=" left-0 w-[38%] h-auto">
                                            <Icon name='Promotion' className='w-full h-dull'/>
                                        </div>
                                        <div className=" w-[50%]">
                                            <div className="">
                                                <TextBlock2
                                                    label={t('htw:p4.label')}
                                                    title={t('htw:p4.title')}
                                                    text={t('htw:p4.text')}
                                                />

                                            </div>
                                            <div className="mt-10 space-y-8">
                                                <div className='space-y-3'>
                                                    <div className='space-y-8'>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p4.groupItem1')}</p>
                                                        </div>
                                                        <div className="flex space-x-3">
                                                            <div className='icon-lg'>
                                                                <Icon className='w-full h-full' name='Check'/>
                                                            </div>
                                                            <p className='text-level2 mt-0 flex-1'>{t('htw:p4.groupItem2')}</p>
                                                        </div>
                                                    </div>
                                                    
                                                
                                                
                                                
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    
                                </div>

                            </div>


                          
                        </div>
                    </div>
            

                </PageInner>
            </PageContainer>

        </DetailsLayout>

    </div>

             
                      
                    
                    
      
  )
}
