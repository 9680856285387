import React from "react";

import {
    TeddraLogo,
    Document,
    Close,
    Reduce,
    Maximize,
    Info,
    Print,
    Refresh,
    Opportunity,
    Bp,
    Deck,
    Demo,
    Home,
    OneMinute,
    OnePage,
    Slides,
    Important,
    BpVersions,
    Deployment,
    Solution,
    About,
    Fullscreen,
    Ecosystem,
    Calendar,
    Desq,
    Jobs,
    Market,
    News,
    Knowledge,
    Files,
    Immo,
    Dock,
    RightPage,
    Qomonpoint,
    Globe,
    TeddraLogoColor,
    Vision,
    Timeline,
    Os,
    RoadmapSm,
    Disque,
    Folders,
    Server,
    Cluster,
    Directory,
    Drive,
    Check,
    Exploitez,
    Compass,
    Search,
    Naviguer,
    Datacenter,
    Events,
    JobOffer,
    MarketStudy,
    Products,
    ProfilPro,
    ProfilEntreprise,
    Service,
    Webpage,
    Training,
    AppelsOffer,
    Chat,
    Filter,
    Phone,
    Pin,
    Preview,
    Share,
    VisitCard,
    Plus,
    Badge,
    GlobeNetwork,
    GlobeSearch,
    GlobeStat,
    Publisher,
    Community,
    Brand,
    Access,
    Platform,
    Contenu,
    Promotion,
    GlobMoney,
    Annuiar,
    Team,
    Linkedin, 
    User,Gridplus
} from "./index";
import { IconOptions } from "../../types";
type ComponentOptions = {
  [key: string]: React.ElementType
}
const Components : ComponentOptions = {
    TeddraLogo,
    Document,
    Close,
    Reduce,
    Maximize,
    Info,
    Print,
    Refresh,
    Opportunity,
    Bp,
    Deck,
    Demo,
    Home,
    OneMinute,
    OnePage,
    Slides,
    Important,
    BpVersions,
    Deployment,
    Solution,
    About,Fullscreen,Ecosystem,
    Calendar,
    Desq,
    Jobs,
    Market,
    News,
    Knowledge,
    Immo,
    Dock,
    Search,
    RightPage,
    Qomonpoint,
    Globe,
    TeddraLogoColor,
    Vision,
    Timeline,
    Os,
    RoadmapSm,
    Disque,
    Files,
    Folders,
    Server,
    Cluster,
    Directory,
    Drive,
    Check,
    Exploitez,
    Compass,
    Naviguer,
    Datacenter,
    Events,
    JobOffer,
    MarketStudy,
    Products,
    ProfilPro,
    ProfilEntreprise,
    Service,
    Webpage,
    Training,
    AppelsOffer,
    Chat,
    Filter,
    Phone,
    Pin,
    Preview,
    Share,
    VisitCard,
    Plus,
    Badge,
    GlobeNetwork,
    GlobeSearch,
    GlobeStat,
    Publisher,
    Community,
    Brand,
    Access,
    Platform,
    Contenu,
    Promotion,
    GlobMoney,
    Annuiar,
    Team,
    Linkedin,
    User,Gridplus
};

export default function Icon({ name, className, style } : IconOptions) {
  if (typeof (Components as any)[name ? name : ''] != "undefined") {
    return React.createElement((Components as any)[name ? name : ''], {
      className: className,
      style: style?style:{}
    });
  }

  return <div></div>;
}
