import React, { useState } from 'react'
import Resource from './Resource'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'antd'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'

const Panel = Collapse.Panel

export default function Resources({data} : {data: {title:string,key:string, blocks:{key:string,name:string,icon:string, description:string, disabled?:boolean,isImage?:boolean, include?:boolean, main?:boolean}[]}[]}) {
    const location = useLocation()
    const navigate = useNavigate()
    const { i18n } = useTranslation()
  return (
    <div className='space-y-16'>
        <Collapse
         bordered={false}
         accordion
        defaultActiveKey={['0']}
         expandIcon={({ isActive  }) =>
             isActive  ? (
             <ChevronDownIcon className="w-3.5 h-3.5" />
             ) : (
             <ChevronRightIcon className="w-3.5 h-3.5" />
             )
         }
        >
            <>
                {data.map((block, i) => (
                    <Panel key={`${i}`} header={<p className='text-groupe !text-[20px]'>{block.title}</p>}>

                        <div className='pl-[20px] pb-[30px] space-y-4' key={i}>
                            <div className='-ml-3'>
                                {block.blocks.filter(item => item.main).map(el => (
                                    <Resource key={el.key} id={el.key} name={el.name} description={el.description} icon={el.icon} action={(key) => navigate(`/${i18n.language}${key}`)} active={el.include ? location.pathname.includes(el.key) : location.pathname === `/${i18n.language}${el.key}`} disabled={el.disabled} isImage={el.isImage}/>
                                ))}

                            </div>
                            <div className="flex flex-wrap gap-x-2 gap-y-4 -ml-3 ">
                                {block.blocks.filter(item => !item.main).map(el => (
                                    <Resource key={el.key} id={el.key} name={el.name} description={el.description} icon={el.icon} action={(key) => navigate(`/${i18n.language}${key}`)} active={el.include ? location.pathname.includes(el.key) : location.pathname === `/${i18n.language}${el.key}`} disabled={el.disabled} isImage={el.isImage}/>
                                ))}

                            </div>
                        </div>
                    </Panel>
                ))}
            
            </>
        </Collapse>
    </div>
  )
}
